import { makeAutoObservable, toJS } from 'mobx';

const initialState = {
  myList: [],
  streams: [],
  streamOrder: [],
  allStreams: [],
  skillsData: undefined,
  catalogProviders: undefined,
  careerFilterItems: undefined,
};

class Store {

  state = initialState;

  constructor() {
    makeAutoObservable(this);
    this.init();
  }

  init() {
    this.state = initialState;
  }

  // /*
  //   * getState
  //   * @param {string}
  //   * @returns {object}
  //   * @example
  //   * const myList = store.getState('myList');
  // */
  // getState(key) {
  //   // Get the current state for the given key
  //   const currentState = this.state[key];
  //   return toJS(currentState);
  // }

  /*
    * getState
    * @param {string}
    * @param {function}
    * @returns {object}
    * @example
    * const myList = await store.getOrFetchState('myList', async () => {
    *  const response = await fetch('https://api.example.com/myList');
    * return response.json();
    * });
  */
  async getState(key, fetchData) {
    // Get the current state for the given key
    const currentState = this.state[key];

    if (typeof fetchData !== 'function' || !fetchData) {
      return currentState;
    }

    // If the state is missing (undefined or null), or if it's an empty array or object when we expect it to have data
    if (currentState === undefined || currentState === null ||
      (Array.isArray(currentState) && currentState.length === 0) ||
      (typeof currentState === 'object' && Object.keys(currentState).length === 0)) {

      // If the state is empty, fetch new data
      const fetchedData = await fetchData();

      // Store the fetched data in the state
      this.state[key] = fetchedData;
      return fetchedData;
    }
    // Return the current state if it's valid and doesn't require a fetch
    return toJS(currentState);
  }

  /*
    * setState
    * @param {string}
    * @param {object}
    * @returns {void}
    * Updates the state with the new value
    * @example
    * store.setState('myList', [1, 2, 3]);
  */
  setState(stateVal, newState) {
    this.state[stateVal] = newState;
  }
}

export const store = new Store();

